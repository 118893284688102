<template>
  <r-page>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
    <template #page-header>
      <h2 class="page-title">Generate Voucher</h2>
      <r-third-level-menu></r-third-level-menu>
    </template>
    <template #page-content>
      <div class="columns">
        <div
          class="column box is-half is-offset-one-quarter"
          style="border: 1px solid #fadada; padding: 20px"
        >
          <h4 class="page-title">Voucher Information</h4>
          <div class="columns mt-3">
            <div class="column is-12">
              <validation-provider
                name="Voucher Code"
                rules="required"
                v-slot="{ errors }"
              >
                <b-field :type="errors[0] && 'is-danger'" :message="errors[0]">
                  <template #label>
                    Voucher Code
                    <b-tooltip
                      label="Required"
                      type="is-dark"
                      position="is-top"
                    >
                      <span class="has-text-danger">*</span>
                    </b-tooltip>
                  </template>
                  <b-input
                    @input="toUpperCase"
                    v-model="voucher.code"
                    placeholder="Enter Voucher Code"
                  ></b-input>
                </b-field>
              </validation-provider>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <validation-provider
                name="Voucher Name"
                rules="required"
                v-slot="{ errors }"
              >
                <b-field :type="errors[0] && 'is-danger'" :message="errors[0]">
                  <template #label>
                    Voucher Name
                    <b-tooltip
                      label="Required"
                      type="is-dark"
                      position="is-top"
                    >
                      <span class="has-text-danger">*</span>
                    </b-tooltip>
                  </template>
                  <b-input
                    v-model="voucher.name"
                    placeholder="Enter Voucher Name"
                  ></b-input>
                </b-field>
              </validation-provider>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <div class="columns">
                <div class="column is-6">
                  <b-field>
                    <template #label>Valid From</template>
                    <b-datepicker
                      v-model="voucher.validFrom"
                      icon-right="calendar-today"
                    ></b-datepicker>
                  </b-field>
                </div>
                <div class="column is-6">
                  <b-field>
                    <template #label>Valid To</template>
                    <b-datepicker
                      v-model="voucher.validTo"
                      icon-right="calendar-today"
                    ></b-datepicker>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <b-field>
                <template #label>Can be use all company?</template>
                <!-- {{ voucher.voucherCompany }} -->
                <b-switch v-model="voucher.canUseByAllCompany"></b-switch>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <b-field>
                <template #label>Enter for Spesific Company</template>
                <!-- {{ voucher.voucherCompany }} -->
                <b-taginput
                  :value="voucher.voucherCompany"
                  :data="filteredTags"
                  autocomplete
                  placeholder="Add a tag"
                  field="company.name"
                  @typing="getFilteredTags"
                  @add="addCompany"
                  @remove="removeCompany"
                  :before-adding="checkCompany"
                  :disabled="voucher.canUseByAllCompany"
                >
                  <!-- v-model="voucher.voucherCompany" -->
                  <!-- @add="changeStructure" -->

                  <template v-slot="props">
                    {{ props.option.name }}
                  </template>
                  <template #empty>There are no items</template>
                </b-taginput>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <b-field>
                <template #label>Status</template>
                <b-select
                  placeholder="Select Voucher Status"
                  v-model="voucher.status"
                  expanded
                >
                  <option value="percentage">Percentage</option>
                  <option value="nominal">Nominal</option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <b-field>
                <template #label>Voucher Quota</template>
                <b-input
                  v-model.number="voucher.quota"
                  placeholder="Enter Voucher Quota"
                ></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <b-field>
                <template #label>Voucher Value</template>
                <b-input
                  v-model="voucher.value"
                  placeholder="Enter Voucher Value"
                ></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <b-field>
                <template #label>Voucher Description</template>
                <b-input
                  v-model="voucher.description"
                  placeholder="Enter Voucher Description"
                  type="textarea"
                ></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <b-button style="float: right" @click="save">Save</b-button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </r-page>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
export default {
  data() {
    return {
      voucher: {
        id: null,
        code: null,
        name: null,
        validFrom: new Date(),
        validTo: new Date(),
        voucherCompany: [],
        status: null,
        quota: null,
        value: null,
        canUseByAllCompany: false,
        description: null,
      },
      isLoading: false,
      companies: null,
      filteredTags: null,
    }
  },
  async created() {
    let companies = await this.getCompanies()
    this.companies = companies.data
    console.log(this.companies)
    if (this.$route.params.id) {
      this.isLoading = true
      let data = await this.showVoucher(this.$route.params.id)
      this.isLoading = false
      this.voucher = data.data
      this.voucher = {
        ...this.voucher,
        validFrom: new Date(this.voucher.validFrom),
        validTo: new Date(this.voucher.validTo),
      }
      console.log(this.voucher)
    }
  },
  methods: {
    ...mapActions({
      generateVoucher: 'voucher/createVoucher',
      // editVoucher: 'voucher/updateVoucher',
      showVoucher: 'voucher/showVoucher',
      getCompanies: 'company/getCompanies',
    }),
    toUpperCase() {
      this.voucher.code = this.voucher.code.toUpperCase()
    },
    save() {
      let data = {
        ...this.voucher,
        validFrom: moment(this.voucher.validFrom).format('YYYY-MM-DD'),
        validTo: moment(this.voucher.validTo).format('YYYY-MM-DD'),
        value: Number(this.voucher.value),
      }
      let module = this.generateVoucher
      module(data).then(
        () => {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Voucher has been saved!',
            confirmButtonText: 'Done',
            customClass: {
              confirmButton: 'button is-success',
            },
          })
          this.$router.push({
            name: 'dashboard-voucher',
          })
        },
        () => {
          this.$swal({
            icon: 'error',
            title: 'Error!',
            text: `Failed to create voucher!`,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'button is-danger',
            },
          })
        }
      )
    },
    // changeStructure(val) {
    //   let findIndex = this.voucher.voucherCompany.findIndex((item) => {
    //     return item.userId == val.userId
    //   })

    //   if (findIndex != -1) {
    //     this.voucher.voucherCompany[findIndex] = {
    //       id: null,
    //       userId: this.voucher.voucherCompany[findIndex].userId,
    //       voucherId: this.voucher.id,
    //       user: {
    //         id: this.voucher.voucherCompany[findIndex].userId,
    //         email: this.voucher.voucherCompany[findIndex].user.email,
    //       },
    //     }
    //   }
    // },
    checkCompany(tag) {
      let map = this.voucher.voucherCompany.map((item) => {
        return item.companyId
      })

      if (!map.includes(tag.companyId)) {
        return true
      }

      return false
    },
    getFilteredTags(text) {
      this.filteredTags = this.companies.filter((option) => {
        return (
          option.name.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
        )
      })
    },
    addCompany(test) {
      console.log(test)
      // let temp = { company: test }
      this.voucher.voucherCompany.push({
        id: null,
        voucher_id: this.voucher.id,
        companyId: test.companyId,
        company: test,
      })
      console.log(this.voucher.voucherCompany)
    },
    removeCompany(test) {
      console.log(test)
      let index = this.voucher.voucherCompany.findIndex(
        (element) => element.companyId === test.companyId
      )
      this.voucher.voucherCompany.splice(index, 1)
      // let temp = { company: test }
    },
  },
}
</script>
